import React from 'react';
import {Container, Row, Col, Button} from "react-bootstrap";
import {dsnCN} from "../../hooks/helper";
import './style.scss';
import email from '../../assets/img/e-mail.png';
import message from '../../assets/img/message.png';

function Copyright({className, ...restProps}) {
    return (
        <div className={dsnCN('copyright', className)} {...restProps}>
            <Container>
            <div className="copyright_flex d-flex justify-content-center">
            <div className="text-center">
                <p>Copyright © {new Date().getFullYear()} All Rights Reserved CybernauticsTech</p>
               
            </div>
            {/* <p><a href="#">Privacy Policy</a></p> */}
            <a href="/contact"  class="btn btn-primary contact nav-link btn-fixed">
                <img src={email} />
            </a>
            <a href="#"  class="btn btn-primary contact nav-link btn-fixed footer-secondicn">
               Hire Me
            </a>
            </div>
            </Container>
        </div>
    );
}

export default Copyright;