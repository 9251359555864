import React from 'react';
import {dsnCN} from "../../hooks/helper";
import footer_bg from "../../assets/img/footer-bg.jpg";

function ContentFooter({className, ...restProps}) {
    return (
        <div className={dsnCN('col-menu' ,className)} {...restProps}>
           <div className='fotter_content_items'>
           <a href='#'> <span>  <img src="/assets/img/contact/location.svg" /></span>
            <p>Cybernauticstech, 5th Floor, D-107 phase 7, industrial area, Sector 73
Mohali, 160055</p></a>
           </div>
           <div className='fotter_content_items'>
           <a href='mailto:info@cybernauticstech.com'> <span><img src="/assets/img/contact/mail.svg" /></span>
            <p class="email-text">hr@cybernauticstech.com</p></a>
           </div>
           <div className='fotter_content_items'>
           <a href='tel:7300663142'> <span><img src="/assets/img/contact/phone.svg" /></span>
            <p>+91 7300663142</p></a>
           </div>
           <div className='fotter_content_items'>
           <a href='tel:7837912354'> <span><img src="/assets/img/contact/phone.svg" /></span>
            <p>+91 7837912354</p></a>
           </div>
        </div>
    );
}

export default ContentFooter;
