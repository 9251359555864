import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import './style.scss';
import { Container, Row, Col } from 'react-bootstrap';
import Axios from 'axios';
import  {useState} from 'react';
import contactbg from '../../assets/img/businesswoman.jpg';

export default function FormValidation() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log(data);
    }
    const url = ""
    const [data, setDate] = useState({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
     
    })
      Axios.post(url,{
        name:data.firstName,
        name:data.lastName,
        name:data.email,
        name:data.password
    
      })
 


    return (
      <section className='cyber_contact_form Cyber_contact' style={{ backgroundImage: `url(${contactbg})` }} id="cyber_contact">
      <Container>
        <Row>
          {/* <Col sm={12} md={6} lg={6} xl={6} xxl={6} className=" order-md-1 order-2 mt-md-0 mt-4">
            <div className='contact_form_image'>
              <img src='/assets/img/contact/Messaging.png' alt='' />
            </div>
          </Col> */}
          <Col sm={12} md={6} lg={6} xl={6} xxl={6} className=" order-md-2 order-1 contact_form">
          <h2 className=''>Contact Us</h2>
            <Form onSubmit={handleSubmit(onSubmit)} className='Cyber_contact'>
                <Form.Field>
                    <label>First Name</label>
                    
                    <input
                        placeholder='First Name'
                        type="text"
                        {...register("firstName", { required: true, maxLength: 10 })}  value={data.firstName}
                    />
                </Form.Field>
                {errors.firstName && <p>Please check the First Name</p>}
                <Form.Field>
                    <label>Last Name</label>
                    <input
                        placeholder='Last Name'
                        type="text"
                        {...register("lastName", { required: true, maxLength: 10 })} value={data.lastName}
                    />
                </Form.Field>
                {errors.lastName && <p>Please check the Last Name</p>}
                <Form.Field>
                    <label>Email</label>
                    <input
                        placeholder='Email'
                        type="email"
                        {...register("email",
                            {
                                required: true,
                                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            })} value={data.email}
                    />
                </Form.Field>
                {errors.email && <p>Please check the Email</p>}
                {/* <Form.Field>
                    <label>Password</label>
                    <input
                        placeholder='Password'
                        type="password"
                        {...register("password", {
                            required: true,
                            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/
                        })} value={data.password}
                    />
                </Form.Field>
                {errors.password && <p>Please check the Password</p>} */}
                <div class="field"><label>Query</label><textarea placeholder="Query" type="text" name="query"></textarea></div>
                <Button type='submit'>Submit</Button>
            </Form>
            </Col>
            </Row>
      </Container>
    </section>
      
    )
}