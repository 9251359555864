import React from "react";
import { Row, Col, Container, Button } from 'react-bootstrap';
import shape from "../../assets/img/shape.png";
import shape2 from "../../assets/img/shape2.png";


function WhatWeDeliver({ className, ...restProps }) {
    return(        
        <section className='cyber_services'>
            <div class="vector_image"> 
                <img src ={shape} alt="shape"/>
            </div>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className='cyber_content_heading services-headings mb-4'>
                            <h5>WHAT WE OFFER</h5>
                            <h2>COURSES WE OFFER</h2>
                            {/* <p>We offer comprehensive web solutions using the latest technologies to enhance the actual value of your business. With pleasant outlooks, functional websites, and profound digital marketing strategies, our dedicated and experienced team will bring your revolutionary business ideas into reality.</p> */}
                        </div>
                    </Col>
                    <Col lg={12}> 
                        <div className='cyber_services_crousel'>
                            {/* <ServicesSlider /> */}
                            <div class="services-cnt">
                                <div className="cyber_services_items">
                                    <img src="/assets/img/newservices/img_1.png" />
                                    <h3>Web Designing</h3>
                                    <p>Master the art of web design in our comprehensive course. Learn HTML, CSS, and JavaScript for creating visually stunning and responsive websites. Explore design principles, user interface, and industry-standard tools.</p>
                                    {/* <Button href='/design' >View More</Button> */}
                                </div>
                            </div>
                            <div class="services-cnt">
                                <div className="cyber_services_items">
                                    <img src="/assets/img/newservices/img_2.png" />
                                    <h3>Web Development</h3>
                                    <p>Dive into HTML, CSS, and JavaScript, PHP to build dynamic and responsive websites. Gain hands-on experience in cutting-edge technologies and frameworks. Elevate your coding expertise and launch a rewarding career in web development.</p>
                                    {/* <Button href='/development' >View More</Button> */}
                                </div>
                            </div>
                            <div class="services-cnt">
                                <div className="cyber_services_items">
                                    <img src="/assets/img/newservices/img_3.png" />
                                    <h3>Digital Marketing</h3>
                                    <p>Dive into the fundamentals of SEO, social media, email campaigns, and analytics. Acquire practical skills to create effective digital strategies and optimise online presence. Perfect for marketers, entrepreneurs, and anyone aiming to thrive in the digital landscape.</p>
                                    {/* <Button href='/seo' >View More</Button> */}
                                </div>
                            </div>
                            <div class="services-cnt">
                                <div className="cyber_services_items">
                                    <img src="/assets/img/newservices/img_5.png" />
                                    <h3>Full Stack Development</h3>
                                    <p>Learn both front-end and back-end technologies, including HTML, CSS, JavaScript, Reactjs, and more. Gain practical experience building dynamic web applications and deploying them. Learn the intricacies of database management and server-side scripting. Ideal for aspiring developers seeking a 360-degree understanding of web development.</p>
                                    {/* <Button href='#' >View More</Button> */}
                                </div>
                            </div>
                            <div class="services-cnt">
                                <div className="cyber_services_items">
                                    <img src="/assets/img/newservices/img_4.png" />
                                    <h3>Quality Assurance Testing</h3>
                                    <p>Learn to ensure the seamless functionality and reliability of software applications and websites also. It's perfect for IT professionals, testers, and aspiring QA engineers. Also know about the principles of software testing, automation, and quality control methodologies and get hands-on experience in industry-standard tools</p>
                                    {/* <Button href='/graphics' >View More</Button> */}
                                </div>
                            </div>
                            <div class="services-cnt">
                                <div className="cyber_services_items">
                                    <img src="/assets/img/newservices/img_8.png" />
                                    <h3>Project Management</h3>
                                    <p>Know about  project planning, execution, and monitoring, mastering essential tools and methodologies. Ideal for aspiring project managers and professionals seeking to enhance their leadership skills. Gain practical insights and real-world application, ensuring you can navigate complex projects with confidence. </p>
                                    {/* <Button href='/graphics' >View More</Button> */}
                                </div>
                            </div>
                            <div class="services-cnt">
                                <div className="cyber_services_items">
                                    <img src="/assets/img/newservices/img_6.png" />
                                    <h3>Business Analysis Essential</h3>
                                    <p>Navigate the complexities of modern business with our Business Analysis Essentials Course. Learn crucial techniques for gathering, analysing, and interpreting data to drive informed decision-making. Explore requirements gathering, process modelling, and stakeholder management. Acquire the tools needed to bridge the gap between business needs and IT solutions.</p>
                                    {/* <Button href='/graphics' >View More</Button> */}
                                </div>
                            </div>
                            <div class="services-cnt">
                                <div className="cyber_services_items">
                                    <img src="/assets/img/newservices/img_7.png" />
                                    <h3>Human Resource Management</h3>
                                    <p>Enhance  your HR skills with our Human Resource Management Course. Know more about key aspects such as recruitment, employee relations, performance management, and strategic planning. Ideal for HR professionals and those aspiring to enter the field. Gain practical insights into talent acquisition, employee development, and organisational success.</p>
                                    {/* <Button href='/graphics' >View More</Button> */}
                                </div>
                            </div>
                        </div>
                    
                    </Col>
                </Row>
            </Container>
            <div class="vector_image vector2"> 
                <img src ={shape2} alt="shape2"/>
            </div>
        </section>
    )
}

export default WhatWeDeliver;