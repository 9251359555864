import React from 'react';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap'; // Make sure to import Container and Row from 'react-bootstrap'
import './style.scss';
import { ProgressBar } from 'react-bootstrap';
const Expert = () => {
  return (
    <>
  
     <section className="expert-section section-padding">
      <Container>
        <Row>
          <Col  sm={12} md={3} lg={3} xl={3} xxl={3} >
           <div class="expert-heading">
              <h2>Expert Business<span>Accounting and Bookkeeping</span></h2>
           </div>
        </Col>
         <Col  sm={12} md={9} lg={9} xl={9} xxl={9} >
                <div class="expert-content">
                <p>Simplify your financial success with our dedicated accounting services. Our skilled professionals cut through complexity, offering clear insights into key statistics to enhance your business objectives. Let us streamline your finances, bringing clarity to your business's bottom line. Elevate your profitability effortlessly with our straightforward and effective accounting expertise.</p>

                <p>At CybernauticsTech, we're dedicated to providing exceptional bookkeeping services, ensuring the accuracy and integrity of your finances. Our robust structure supports smart decision-making and sustained business growth, managing everything from daily financial records to comprehensive account reconciliations. </p>
                </div>
            </Col>
        </Row>
        {/* Uncomment the following line if you have a component named Newsletter */}
        {/* <Newsletter /> */}
      </Container>
    </section>

    <section className="account-services-section section-padding" >
      <Container>
        <Row>
          <Col  sm={12} md={4} lg={4} xl={4} xxl={4}  >
            <div className="account-service-img">
              <img src="/assets/img/accounts/account01.jpg"/>
            </div>
          </Col>
          <Col  sm={12} md={8} lg={8} xl={8} xxl={8}  >
            
            <div className="expert-heading account-services-content expert-content">
                <h5>Services</h5>
                <h2>Accounting <span>Services</span></h2>
                <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                  <Tab eventKey={1} title="United States">
                    <div className='team-item'>

                      <div className="team-info">
                          <h3 className="team-name">1. Accounting Standards</h3>
                          <p className="team-desc">GAAP (Generally Accepted Accounting Principles): The U.S. primarily follows GAAP, a set of accounting principles, standards, and procedures that companies use to compile their financial statements.</p>
                      </div>

                      <div className="team-info">
                          <h3 className="team-name">2. Financial Statements</h3>
                          <p className="team-desc">Balance Sheet: Presents assets, liabilities, and equity. 
                          <br></br>Income Statement: Shows revenues, expenses, and net income.<br></br>Cash Flow Statement: Reflects the company's cash inflows and outflows.</p>
                      </div>
                      <div className="team-info">
                          <h3 className="team-name">3. Taxation</h3>
                          <p className="team-desc">IRS (Internal Revenue Service): The IRS governs federal taxation, and companies may also be subject to state taxes.</p>
                      </div>
                      <div className="team-info">
                          <h3 className="team-name">4. Regulatory Bodies</h3>
                          <p className="team-desc">SEC (Securities and Exchange Commission): Regulates the securities industry and ensures proper disclosure of financial information.</p>
                      </div>
                      <div className="team-info">
                          <h3 className="team-name">5. Bookkeeping Software</h3>
                          <p className="team-desc">QuickBooks, Xero, etc.: Popular choices for bookkeeping and accounting.</p>
                      </div>

                    </div>
                    
                  </Tab>
                  <Tab eventKey={2} title="United Kingdom">
                    <div className='team-item'>
                      <div className="team-info">
                          <h3 className="team-name">1.  Accounting Standards</h3>
                          <p className="team-desc">UK GAAP: Historically, the UK followed its own Generally Accepted Accounting Principles. However, recently, the UK has transitioned to adopt IFRS (International Financial Reporting Standards) for many companies.</p>
                      </div>

                      <div className="team-info">
                          <h3 className="team-name">2. Financial Statements</h3>
                          <p className="team-desc">Balance Sheet, Profit and Loss Account, Cash Flow Statement: Similar to the U.S., but the terminology might differ.</p>
                      </div>
                      <div className="team-info">
                          <h3 className="team-name">3. Taxation</h3>
                          <p className="team-desc">HMRC (Her Majesty's Revenue and Customs): Overseas taxation in the UK.</p>
                      </div>
                      <div className="team-info">
                          <h3 className="team-name">4. Regulatory Bodies</h3>
                          <p className="team-desc">FRC (Financial Reporting Council): Provides oversight of corporate governance and accounting standards.</p>
                      </div>
                      <div className="team-info">
                          <h3 className="team-name">5. Bookkeeping Software</h3>
                          <p className="team-desc">Sage, QuickBooks, etc.: Similar software is used, but some features and terminology may vary.</p>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
            </div>
          </Col>
         
          
        </Row>
      </Container>
    </section>
    <section className='accounts-who section-padding'>
      <Container>
        <Row>
            <Col  sm={12} md={6} lg={6} xl={6} xxl={6}  >
            <div class="expert-heading account-who expert-content">
                {/* <h5>Who We Are</h5> */}
                <h2>General <span>Tips</span></h2>
                <p>Currency: Pay attention to the currency used in financial statements (USD in the U.S., GBP in the UK).</p>
                <p>VAT (Value Added Tax): Relevant in the UK but not part of the U.S. tax system.</p>
                <p>Legal Structure: Understand the legal structure of the company as it affects accounting practices.</p>
                <p>Choose CybernauticsTech, for professional advice from accountants familiar with the specific regulations in each jurisdiction is highly recommended to ensure compliance and accuracy in financial reporting.</p>                
              </div>
            </Col>
            <Col  sm={12} md={6} lg={6} xl={6} xxl={6}  >
              <div class="account-service-img">
                <img src="/assets/img/accounts/accountingservices.jpg" alt=""/>
              </div>
            </Col>           
        </Row>
      </Container>
    </section>
    {/* <section class="section-padding accout-offer">
      <Container>
          <div class="expert-heading">
              <h5 class="text-center">Our Services</h5>
              <h2 class="text-center">What Service <span>We Offer</span></h2>
          </div>
          <Row className='ac-offer-first-row'>
              <Col  sm={12} md={4} lg={4} xl={4} xxl={4}>
                <div class="account_technology_wrapper">
                  <div class="account_item_box">
                    <div class="account_item"><img src="/assets/img/accounts/book-keeping.png" alt="" /></div>
                    <h4>Online Bookkeeping</h4>
                    <p>Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do eiusmod tempor incididunt</p>
                    <div class="account-btn">
                        <a href="#" class="btn">Read More</a>
                    </div>
                    </div>
                </div>
              </Col>
              <Col  sm={12} md={4} lg={4} xl={4} xxl={4}>
                <div class="account_technology_wrapper">
                  <div class="account_item_box">
                    <div class="account_item"><img src="/assets/img/accounts/tax.png" alt="" /></div>
                    <h4>Tax Preparation</h4>
                    <p>Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do eiusmod tempor incididunt</p>
                    <div class="account-btn">
                        <a href="#" class="btn">Read More</a>
                    </div>
                    </div>
                </div>
              </Col>
              <Col  sm={12} md={4} lg={4} xl={4} xxl={4}>
                <div class="account_technology_wrapper">
                  <div class="account_item_box">
                  <div class="account_item"><img src="/assets/img/accounts/financial.png" alt="" /></div>
                    <h4>Financial Planning</h4>
                    <p>Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do eiusmod tempor incididunt</p>
                    <div class="account-btn">
                        <a href="#" class="btn">Read More</a>
                    </div>
                    </div>
                </div>
              </Col>
              </Row>
              <Row>
              <Col  sm={12} md={4} lg={4} xl={4} xxl={4}>
                <div class="account_technology_wrapper">
                  <div class="account_item_box">
                    <div class="account_item"><img src="/assets/img/accounts/financial-planning.png" alt="" /></div>
                    <h4>Payroll Data Entry</h4>
                    <p>Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do eiusmod tempor incididunt</p>
                    <div class="account-btn">
                        <a href="#" class="btn">Read More</a>
                    </div>
                    </div>
                </div>
              </Col>
              <Col  sm={12} md={4} lg={4} xl={4} xxl={4}>
                <div class="account_technology_wrapper">
                  <div class="account_item_box">
                    <div class="account_item"><img src="/assets/img/accounts/planning.png" alt="" /></div>
                    <h4>Investment Planning</h4>
                    <p>Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do eiusmod tempor incididunt</p>
                    <div class="account-btn">
                        <a href="#" class="btn">Read More</a>
                    </div>
                    </div>
                </div>
              </Col>
              <Col  sm={12} md={4} lg={4} xl={4} xxl={4}>
                <div class="account_technology_wrapper">
                  <div class="account_item_box">
                    <div class="account_item"><img src="/assets/img/accounts/budget.png" alt="" /></div>
                    <h4>Accounts Outsourcing</h4>
                    <p>Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do eiusmod tempor incididunt</p>
                    <div class="account-btn">
                      <a href="#" class="btn">Read More</a>
                  </div>
                    </div>
                </div>
              </Col>
          </Row>
      </Container>
    </section> */}
    {/* <section class="section-padding accout-process">
      <Container>
        <div class="expert-heading">
            <h5 class="text-center">Our Process</h5>
            <h2 class="text-center">Easy Steps To <span>Get Your Solutions</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
        </div>
        <Row>
          <Col  sm={12} md={4} lg={4} xl={4} xxl={4}>
                  <div class="account_technology_wrapper">
                    <div class="account_item_box">
                      <div class="account_item"><img src="/assets/img/accounts/work-schedule.png" alt="" /></div>
                      <h4>Make An Appointment</h4>
                      <p>Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do eiusmod tempor incididunt</p>
                    </div>
                  </div>
          </Col>
          <Col  sm={12} md={4} lg={4} xl={4} xxl={4}>
                  <div class="account_technology_wrapper">
                    <div class="account_item_box">
                      <div class="account_item"><img src="/assets/img/accounts/consultation.png" alt="" /></div>
                      <h4>Get Consultation</h4>
                      <p>Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do eiusmod tempor incididunt</p>
                      </div>
                  </div>
          </Col>
          <Col  sm={12} md={4} lg={4} xl={4} xxl={4}>
                  <div class="account_technology_wrapper">
                    <div class="account_item_box">
                      <div class="account_item"><img src="/assets/img/accounts/puzzle.png" alt="" /></div>
                      <h4>Your Problem Solved</h4>
                      <p>Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do eiusmod tempor incididunt</p>
                      </div>
                  </div>
          </Col>
        </Row>
      </Container>
    </section> */}
    </>
  );
};

export default Expert;
